.text {

  // &--phone { @include responsive_text_size('phone') }
  &--base { @include responsive_text_size('base') }
  &--xl { @include responsive_text_size('xlarge') }
  &--lg { @include responsive_text_size('large') }
  &--md { @include responsive_text_size('medium') }
  &--sm { @include responsive_text_size('small') }

  &--primary { color: $color_primary; }
  &--secondary { color: $color_secondary; }
  &--success { color: $color_success; }
  &--failed { color: $color_failed; }
  &--warning { color: $color_warning; }
  &--action { color: $color_action; }
  &--white { color: $white; }

  &--center {
    text-align: center;

    &#{\@vp-xs} {
      @media screen and (max-width: $vp_xs) {
        text-align: center;
      }
    }

    &#{\@vp-sm} {
      @media screen and (min-width: $vp_sm) and (max-width: $vp_md_max) {
        text-align: center;
      }
    }

    &#{\@vp-md} {
      @media screen and (min-width: $vp_md) and (max-width: $vp_lg_max) {
        text-align: center;
      }
    }

    &#{\@vp-lg} {
      @media screen and (min-width: $vp_lg) and (max-width: $vp_hd_max) {
        text-align: center;
      }
    }

    &#{\@vp-hd} {
      @media screen and (min-width: $vp_hd) and (max-width: $vp_hd_max) {
        text-align: center;
      }
    }

    &#{\@vp-4k} {
      @media screen and (min-width: $vp_4k) {
        text-align: center;
      }
    }
  }

  &--right {
    text-align: right;

    &#{\@vp-xs} {
      @media screen and (max-width: $vp_xs) {
        text-align: right;
      }
    }

    &#{\@vp-sm} {
      @media screen and (min-width: $vp_sm) and (max-width: $vp_md_max) {
        text-align: right;
      }
    }

    &#{\@vp-md} {
      @media screen and (min-width: $vp_md) and (max-width: $vp_lg_max) {
        text-align: right;
      }
    }

    &#{\@vp-lg} {
      @media screen and (min-width: $vp_lg) and (max-width: $vp_hd_max) {
        text-align: right;
      }
    }

    &#{\@vp-hd} {
      @media screen and (min-width: $vp_hd) and (max-width: $vp_hd_max) {
        text-align: right;
      }
    }

    &#{\@vp-4k} {
      @media screen and (min-width: $vp_4k) {
        text-align: right;
      }
    }
  }

  &-shadow--lv1 {
    text-shadow: 0 4px 10px $rgb_black_10;
  }

  &--thin { font-weight: $font_thin }
  &--extralight { font-weight: $font_extralight }
  &--light { font-weight: $font_light }
  &--normal { font-weight: $font_normal }
  &--medium { font-weight: $font_medium }
  &--semibold { font-weight: $font_semibold }
  &--bold { font-weight: $font_bold }
  &--extrabold { font-weight: $font_extrabold }
  &--black { font-weight: $font_black }
}

.icon {
  display: inline-block;

  &--insider {
    background-image: url($images_path + 'ie-logo.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;
    width: 100px;
    height: 43px;
  }
}
