.container {
  width: 100%;

  @media screen and (min-width: $vp_sm) {
    width: 100%;
  }

  @media screen and (min-width: $vp_md) {
    width: 90vw;
  }

  @media screen and (min-width: $vp_lg) {
    width: 80vw;
  }
}

.container,
.container-fluid {

  .row {
    &--gutterless {
      > [class*='col-'] {
        padding-right:0;
        padding-left:0;
      }
    }
  }
}
