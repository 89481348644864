// Glyphicons font path
$icon-font-path: '../fonts/';

$images_path  : '../images/';

$logo_white_svg : $images_path + 'JMR-logo-white.svg';
$logo_dark_svg : $images_path + 'JMR-logo-dark.svg';
$logo_default_svg : $images_path + 'JMR-logo-default.svg';
$logo_width_xs: 160;
$logo_width_sm: 190;
$logo_width_md: 300;
$logo_width_lg: 300;
$logo_width_hd: 320;
$logo_width_4k: 300;
$logo_main: $logo_default_svg;

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Viewports
$vp_phone_sm: 260px;
$vp_xs      : 480px;
$vp_sm      : 768px;
$vp_md      : 992px;
$vp_lg      : 1200px;
$vp_hd      : 1920px;
$vp_4k      : 3840px;

$vp_phone_sm_max: $vp_phone_sm - 1;
$vp_xs_max      : $vp_xs - 1;
$vp_sm_max      : $vp_sm - 1;
$vp_md_max      : $vp_md - 1;
$vp_lg_max      : $vp_lg - 1;
$vp_hd_max      : $vp_hd - 1;
$vp_4k_max      : $vp_4k - 1;

$breakpoints: (
  'phone': $vp_phone_sm,
  'xs'   : $vp_xs,
  'sm'   : $vp_sm,
  'md'   : $vp_md,
  'lg'   : $vp_lg,
  'hd'   : $vp_hd,
  '4k'   : $vp_4k,
);

// Colors
$color_primary  : #ff0000;
$color_secondary: tomato;
$color_success  : #1976d2;
$color_failed   : #ff1744;
$color_warning  : #ef6c00;
$color_action   : #ff1493;
$color_google   : #d34836;
$color_facebook : #3b5998;
$color_twitter  : #00aced;

// Shades
$white: #fff;
$gray_lv1: darken($white, 10%);
$gray_lv2: darken($white, 25%);
$gray_lv3: darken($white, 35%);
$gray_lv4: darken($white, 45%);
$gray_lv5: darken($white, 55%);
$gray_lv6: darken($white, 65%);
$gray_lv7: darken($white, 75%);
$gray_lv8: darken($white, 85%);
$black   : darken($white, 95%);

//RGBA Black
$rgb_black_10 : rgba(0, 0, 0, .1);
$rgb_black_20 : rgba(0, 0, 0, .2);
$rgb_black_30 : rgba(0, 0, 0, .3);
$rgb_black_40 : rgba(0, 0, 0, .4);
$rgb_black_50 : rgba(0, 0, 0, .5);
$rgb_black_60 : rgba(0, 0, 0, .6);
$rgb_black_70 : rgba(0, 0, 0, .7);
$rgb_black_80 : rgba(0, 0, 0, .8);
$rgb_black_90 : rgba(0, 0, 0, .9);

//RGBA White
$rgb_white_10 : rgba(255, 255, 255, .1);
$rgb_white_20 : rgba(255, 255, 255, .2);
$rgb_white_30 : rgba(255, 255, 255, .3);
$rgb_white_40 : rgba(255, 255, 255, .4);
$rgb_white_50 : rgba(255, 255, 255, .5);
$rgb_white_60 : rgba(255, 255, 255, .6);
$rgb_white_70 : rgba(255, 255, 255, .7);
$rgb_white_80 : rgba(255, 255, 255, .8);
$rgb_white_90 : rgba(255, 255, 255, .9);

$site_bg_color: $white;
$link_color: $color_primary;

// Fonts and Icons
$source_sans_pro: 'Source Sans Pro', Calibri, Candara, Arial, sans-serif;
$roboto         : 'Roboto', Verdana, sans-serif;
$montserrat     : 'Montserrat', Verdana, sans-serif;
$helvetica_neue : 'Helvetica', Verdana, sans-serif;
$open_sans      : 'Open Sans', Verdana, sans-serif;
$font_awesome   : FontAwesome;
$icon_set       : $font_awesome;
$font_family    : $montserrat;
$font_color     : $gray_lv8;

// Font Sizes
$font_xs  : 1rem;
$font_sm  : 1.4rem;
$font_base: 1.9rem;
$font_md  : 3.6rem;
$font_lg  : 4.2rem;
$font_xl  : 5.2rem;
$font_xxl : 7.43rem;
$font_huge: 10.699rem;

// Font Weights
$font_thin      : 100;
$font_extralight: 200;
$font_light     : 300;
$font_normal    : 400;
$font_medium    : 500;
$font_semibold  : 600;
$font_bold      : 700;
$font_extrabold : 800;
$font_black     : 900;

$line_height : $font_base / .60;

//Navbar
$navbar_height_xs     : 45;
$navbar_height_sm     : 55;
$navbar_height_md     : 75;
$navbar_height_lg     : 75;
$navbar_height_hd     : 90;
$navbar_height_4k     : 120;
$navbar_font_size_xs  : $font_sm;
$navbar_font_size_sm  : $font_sm - .2;
$navbar_font_size_md  : $font_sm;
$navbar_font_size_lg  : $font_sm;
$navbar_font_size_hd  : $font_base + .4;
$navbar_font_size_4k  : $font_md;
$navbar_color_dark    : $gray_lv8;
$navbar_color_white   : $white;
$navbar_color_default : $navbar_color_white;
$navbar_color_inverted: $navbar_color_dark;
$navbar_shadow        : 0 2px 5px $rgb_black_20;

//Footer
$footer_height_xs     : 60;
$footer_height_sm     : 60;
$footer_height_md     : 60;
$footer_height_lg     : 60;
$footer_height_hd     : 60;
$footer_height_4k     : 60;

$footer_padding_xs     : ($footer_height_xs / 6) + px 0;
$footer_padding_sm     : ($footer_height_sm / 6) + px 0;
$footer_padding_md     : ($footer_height_md / 6) + px 0;
$footer_padding_lg     : ($footer_height_lg / 6) + px 0;

$footer_color_dark    : #242424;
$footer_color_white   : $white;
$footer_color_default : $gray_lv1;
$footer_color_inverted: $footer_color_dark;
$footer_font_color: $gray_lv6;
$footer_border_color: $gray_lv1;


$text-sizing: (
  'xlarge': (
    'default': (
      'font-size': $font_xl,
      'line-height': $font_xl / .80,
      'margin': 0 0 20px 0
    ),
    'xs': (
      'font-size': $font_xl,
      'line-height': $font_xl / .80,
      'margin': 0 0 20px 0
    ),
    'sm': (
      'font-size': $font_xl,
      'line-height': $font_xl / .80,
      'margin': 0 0 20px 0
    )
  ),
  'large': (
    'default': (
      'font-size': $font_md,
      'line-height': $font_md / .80,
      'margin': 0 0 20px 0
    ),
    'xs': (
      'font-size': $font_md,
      'line-height': $font_md / .80,
      'margin': 0 0 20px 0
    ),
    'sm': (
      'font-size': $font_lg,
      'font-weight': $font_black,
      'line-height': $font_lg + ($font_lg * .1571428571428571),
      'margin': 0 0 (4.2 * 64.28571428571429) / 100 + px 0,
      'text-transform': uppercase
    )
  ),
  'medium': (
    'default': (
      'font-size': $font_base,
      'font-weight': $font_light,
      'line-height': $font_base / .80,
      'margin': 0 0 20px 0
    ),
    'xs': (
      'font-size': $font_md,
      'font-weight': $font_thin,
      'letter-spacing': .25rem,
      'line-height': $font_md / .80,
      'margin': 0 0 20px 0,
      'text-transform': uppercase
    )
  ),
  'base': (
    'default': (
      'font-size': $font_base,
      'line-height': $font_base / .80,
      'margin': 0 0 10px 0
    )
  ),
  'small': (
    'default': (
      'font-size': $font_sm,
      'line-height': $font_sm / .80,
      'margin': 0 0 10px 0
    )
  ),
  'xsmall': (
    'default': (
      'font-size': $font_xs,
      'line-height': $font_xs / .80,
      'margin': 0 0 10px 0
    )
  )
);

$text_shadow_lv1: 0 1px 2px $rgb_black_80;
$text_shadow_lv2: 0 2px 4px $rgb_black_80;
$text_shadow_lv3: 0 4px 6px $rgb_black_80;
$text_shadow_lv4: 0 6px 8px $rgb_black_80;
$text_shadow_lv5: 0 8px 10px $rgb_black_80;
$text_shadow_lv6: 0 10px 12px $rgb_black_80;
$text_shadow_lv7: 0 12px 14px $rgb_black_80;
$text_shadow_lv8: 0 14px 16px $rgb_black_80;

$box_shadow_lv1: 0 1px 2px $rgb_black_30;
$box_shadow_lv2: 0 2px 4px $rgb_black_30;
$box_shadow_lv3: 0 4px 6px $rgb_black_30;
$box_shadow_lv4: 0 6px 8px $rgb_black_30;
$box_shadow_lv5: 0 8px 10px $rgb_black_30;
$box_shadow_lv6: 0 10px 12px $rgb_black_30;
$box_shadow_lv7: 0 12px 14px $rgb_black_30;
$box_shadow_lv8: 0 14px 16px $rgb_black_30;

$input_radius    : 0;
$input_height    : 45px;
$input_border    : 1px solid $gray_lv2;
$input_box_shadow: none;
$input_font_color: $gray_lv4;
$input_font_size: $font_base;
$input_font_style: italic;

$label_font_size: $font_base;
$label_font_style: italic;

$button_radius     : 4px;
$button_height     : 45px;
$button_shadow     : none;
$button_font_family: $font_family;
$button_font_weight: $font_semibold;
$button_font_size  : $font_base;
$button_font_style : italic;
$button_padding    : 11px 12px;
$button_margin     : 0 5px 10px 0;

$well_radius  : 4px;
