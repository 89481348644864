/*https://ux.mailchimp.com/patterns/helpers*/
$box_model_levels: 8;
$box_model_increment_of: 10;
$box_model_types: 'padding', 'margin';

/*
 * Compiles the padding/margins for
 * top, right, bottom, left and default
*/

@for $i from 0 through $box_model_levels {

  @each $model in $box_model_types {
    .#{$model} {

      &--lv#{$i} {
        #{$model}: ($box_model_increment_of * $i) + px;
      }

      &-top {
        &--lv#{$i} {
          #{$model}-top: ($box_model_increment_of * $i) + px;
        }
      }

      &-right {
        &--lv#{$i} {
          #{$model}-right: ($box_model_increment_of * $i) + px;
        }
      }

      &-bottom {
        &--lv#{$i} {
          #{$model}-bottom: ($box_model_increment_of * $i) + px;
        }
      }

      &-left {
        &--lv#{$i} {
          #{$model}-left: ($box_model_increment_of * $i) + px;
        }
      }

      &-left-right {
        &--lv#{$i} {
          #{$model}-left: ($box_model_increment_of * $i) + px;
          #{$model}-right: ($box_model_increment_of * $i) + px;
        }
      }

      &-top-bottom {
        &--lv#{$i} {
          #{$model}-top: ($box_model_increment_of * $i) + px;
          #{$model}-bottom: ($box_model_increment_of * $i) + px;
        }
      }
    }
  }
}

/*
 * Compiles the !padding/!margins
 * using the !important rule
 * just in case overriding is
 * close to impossible
*/

@for $i from 0 through $box_model_levels {

  @each $model in $box_model_types {
    .\!#{$model} {

      &--lv#{$i} {
        #{$model}: ($box_model_increment_of * $i) + px !important;
      }

      &-top {
        &--lv#{$i} {
          #{$model}-top: ($box_model_increment_of * $i) + px !important;
        }
      }

      &-right {
        &--lv#{$i} {
          #{$model}-right: ($box_model_increment_of * $i) + px !important;
        }
      }

      &-bottom {
        &--lv#{$i} {
          #{$model}-bottom: ($box_model_increment_of * $i) + px !important;
        }
      }

      &-left {
        &--lv#{$i} {
          #{$model}-left: ($box_model_increment_of * $i) + px  !important;
        }
      }

      &-left-right {
        &--lv#{$i} {
          #{$model}-left: ($box_model_increment_of * $i) + px !important;
          #{$model}-right: ($box_model_increment_of * $i) + px !important;
        }
      }

      &-top-bottom {
        &--lv#{$i} {
          #{$model}-top: ($box_model_increment_of * $i) + px !important;
          #{$model}-bottom: ($box_model_increment_of * $i) + px !important;
        }
      }
    }
  }
}
