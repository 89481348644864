.title {
  padding: 10px;
  margin-bottom: 20px;

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }

  &__background {

    &--primary {
      background-color: $color_primary;

      h1,
      h2,
      h3,
      h4 {
        color: $color_secondary;
      }
    }
  }
}
