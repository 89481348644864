.display {

  &--hidden {
    display: none;
  }

  &--table {
    display: table;
  }

  &--row {
    display: table-row;
  }

  &--td {
    display: table-cell;

    &.middle {
      vertical-align: middle;
    }
  }
}