.dropdown-menu {
  padding: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;

  .divider {
    margin: 0;
  }

  .menu__item--logout {
    background-color: $color_action;
    color: $white;

    a {
      color: $white;
    }
  }
}
