.navbar {

  &--ui {
    @extend .navbar-default;
    background-color: $navbar_color_default;
    border: 0;
    box-shadow: $navbar_shadow;
    min-height: $navbar_height_xs + px;
    margin-bottom: 0;
    transition: all .9s ease;

    @media screen and (min-width: $vp_sm) {
      min-height: $navbar_height_sm + px;
    }

    @media screen and (min-width: $vp_md) {
      min-height: $navbar_height_md + px;
    }

    @media screen and (min-width: $vp_lg) {
      min-height: $navbar_height_lg + px;
    }

    @media screen and (min-width: $vp_hd) {
      min-height: $navbar_height_hd + px;
    }

    @media screen and (min-width: $vp_4k) {
      min-height: $navbar_height_4k + px;
    }

    .navbar-header {

      &.block {
        display: block;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
      }
    }

    > .container .navbar-brand,
    > .container-fluid .navbar-brand {
      width: $logo_width_xs + px;
      height: $navbar_height_xs + px;
      background-image: url($logo_main);
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      transition: all .9s ease;
      margin-left: 5px;

      @media screen and (min-width: $vp_sm) {
        width: $logo_width_sm + px;
        height: $navbar_height_sm + px;
      }

      @media screen and (min-width: $vp_md) {
        width: $logo_width_md + px;
        height: $navbar_height_md + px;
      }

      @media screen and (min-width: $vp_lg) {
        width: $logo_width_lg + px;
        height: $navbar_height_lg + px;
      }

      @media screen and (min-width: $vp_hd) {
        width: $logo_width_hd + px;
        height: $navbar_height_hd + px;
      }

      @media screen and (min-width: $vp_4k) {
        width: $logo_width_4k + px;
        height: $navbar_height_4k + px;
      }

      &.center {
        display: block;
        margin: 0 auto;
        float: none;
      }
    }

    .navbar-nav > li > a {
      font-size: $navbar_font_size_xs;
      font-weight: $font_extralight;
      padding-top: 0;
      padding-bottom: 0;
      line-height: $navbar_height_xs + px;
      min-height: $navbar_height_xs + px;
      text-transform: uppercase;
      transition: all .9s ease;

      @media screen and (min-width: $vp_sm) {
        font-size: $navbar_font_size_sm;
        line-height: $navbar_height_sm + px;
        min-height: $navbar_height_sm + px;
      }

      @media screen and (min-width: $vp_md) {
        font-size: $navbar_font_size_md;
        line-height: $navbar_height_md + px;
        min-height: $navbar_height_md + px;
      }

      @media screen and (min-width: $vp_lg) {
        font-size: $navbar_font_size_lg;
        line-height: $navbar_height_lg + px;
        min-height: $navbar_height_lg + px;
      }

      @media screen and (min-width: $vp_hd) {
        font-size: $navbar_font_size_hd;
        line-height: $navbar_height_hd + px;
        min-height: $navbar_height_hd + px;
      }

      @media screen and (min-width: $vp_4k) {
        font-size: $navbar_font_size_4k;
        line-height: $navbar_height_4k + px;
        min-height: $navbar_height_4k + px;
      }
    }

    .navbar-nav > .active > a,
    .navbar-nav > .active > a:hover {
      color: $color_primary;
      background-color: transparent;
    }

    &.navbar--overlay {
      background-color: transparent;
      box-shadow: none;
    }

    &.navbar--overlay > .container .navbar-brand,
    &.navbar--overlay > .container-fluid .navbar-brand {
      background-image: url($logo_white_svg);
    }

    &.navbar--overlay .navbar-nav > li > a {
      color: $white;

      &:hover {
        color: darken($white, 10%);
      }
    }

    .navbar-toggle {
      border: 0;
      height: $navbar_height_xs + px;
      line-height: $navbar_height_xs + px;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0 10px;

      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
      }

      .icon-bar {
        background-color: $color_primary;
      }
    }

    &.navbar--overlay .navbar-toggle {

      .icon-bar {
        background-color: $white;
      }
    }

    // &.navbar--overlay .navbar-nav > .active > a,
    // &.navbar--overlay .navbar-nav > .active > a:hover {
    //   color: $color_primary;
    //   background-color: transparent;
    // }

    .navbar-collapse {
      border: 0;

      &.collapsing,
      &.in {
        min-height: calc(100vh - #{$navbar_height_xs + px});
      }
    }

    &.navbar--overlay .navbar-collapse {

      @media screen and (max-width: $vp_xs_max) {
        background-color: #384444;
      }
    }

    .navbar-nav {

      @media screen and (max-width: $vp_xs_max) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}
