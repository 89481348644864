.carousel {

  &--fade {

    .carousel-outer {
      position: relative;
    }

    .carousel-inner {

      .item {
        transition-property: opacity;
      }

      .item,
      .active.left,
      .active.right {
        opacity: 0;
      }

      .active,
      .next.left,
      .prev.right {
        opacity: 1;
      }

      .next,
      .prev,
      .active.left,
      .active.right {
        left: 0;
        transform: translate3d(0, 0, 0);
      }
    }

    .carousel-control {
      z-index: 2;

      .fa-angle-left,
      .fa-angle-right {
        font-size: $font_xxl;
        position: absolute;
        top: 45%;
      }
    }
  }

  .item__image {
    overflow: hidden;
    position: relative;

    @media screen and (min-width: $vp_sm) {
      height: 700px;
    }

    img {
      // filter: brightness(30%);

      @media screen and (min-width: $vp_sm) {
        width: 120%;
        top: -20%;
        bottom: -20%;
        margin: auto;
        position: absolute;
      }
    }
  }

  .carousel-caption {
    text-align: center;
    bottom: 0;
    top: 0;

    @media screen and (min-width: $vp_sm) {
      padding: 0 0;
    }

    .caption--container {
      display: table;
      width: 100%;

      @media screen and (min-width: $vp_sm) {
        height: 700px;
      }
    }

    .caption {
      display: table-cell;
      vertical-align: middle;
    }

    h4 {
      color: $white;
      font-size: $font_xl;
      font-weight: $font_light;
      line-height: $font_xl;
      margin-bottom: 30px;
    }
  }

  .carousel-indicators {
    margin: 10px 0 0;
    overflow: auto;
    position: static;
    text-align: left;
    white-space: nowrap;
    width: 100%;
  }

  .carousel-indicators li {
    background-color: transparent;
    -webkit-border-radius: 0;
    border-radius: 0;
    display: inline-block;
    height: auto;
    margin: 0 !important;
    width: 200px;
    height: 125px;
    overflow: hidden;
  }

  .carousel-indicators li img {
    display: block;
  }
}
