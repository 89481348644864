.footer {
  // Use the two rules below to give the float bottom (see base for body padding properties)
  background-color: $footer_color_default;
  border-top: 1px solid $footer_border_color;
  color: $footer_font_color;
  padding: $footer_padding_xs;
  height: $footer_height_xs + px;
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  min-height: $footer_height_xs + px;

  @media screen and (min-width: $vp_sm) {
    height: $footer_height_sm + px;
    padding: $footer_padding_sm;
  }

  @media screen and (min-width: $vp_md) {
    height: $footer_height_md + px;
    padding: $footer_padding_md;
  }

  @media screen and (min-width: $vp_lg) {
    height: $footer_height_lg + px;
    padding: $footer_padding_lg;
  }

  p {
    margin-bottom: 5px;
  }

  &.default {
    background-color: $footer_color_default;
  }

  &.inverted {
    background-color: $footer_color_inverted;
  }

  .text-muted {
    color: $gray_lv4;
  }

  .nav-pills > li {
    display: table-cell !important;
    vertical-align: middle;
    width: 1% !important;
  }

  .nav-pills > li + li {
    margin-left: 0;
  }

  .nav > li > a,
  .nav-pills > li > a {
    color: $color_primary;
    font-size: $font_lg;
    padding: 0 15px;


    &:hover {
      background-color: transparent;
      i {
        filter: contrast(50%);
      }
    }
  }
}
