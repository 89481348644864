$main_hero: url($images_path + '/jumbotron/lens.jpg');

.jumbotron {
  padding: 0;
  margin-bottom: 0;

  &--fullwidth {
    width: 100%;
  }

  &--fullheight {
    min-height: calc(100vh - #{($navbar_height_xs - 50) + px});
    position: relative;
    margin-bottom: 0;
    padding: 20px 0;

    @media screen and (min-width: $vp_sm) {
      padding: 10vh 0;
      min-height: 660px;
    }

    &.jumbotron--nav-overlay {
      min-height: 100vh;
      padding-top: ($navbar_height_xs * 2) + px ;

      @media screen and (min-width: $vp_sm) {
        min-height: 100vh;
        padding-top: ($navbar_height_sm * 2) + px;
      }

      @media screen and (min-width: $vp_md) {
        padding-top: ($navbar_height_md * 2) + px;
      }

      @media screen and (min-width: $vp_lg) {
        padding-top: ($navbar_height_lg * 2) + px;
      }

    }
  }

  &.main {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    background-image: $main_hero;

    h1 {
      color: $color_primary;
      font-size: $font_md - .6;
      line-height: $font_md - .6;
      font-weight: $font_black;
      color: $white;
      margin-bottom: 0;

      @media screen and (min-width: $vp_sm) {
        font-size: $font_lg;
        line-height: $font_lg;
      }

      @media screen and (min-width: $vp_hd) {
        font-size: $font_xl;
        line-height: $font_xl;
        margin-top: 75px;
      }
    }

    h2 {
      font-size: $font_md - .6;
      line-height: $font_md - .6;
      font-weight: $font_thin;
      color: $white;
      margin-top: 0;
      margin-bottom: 30px;

      @media screen and (min-width: $vp_sm) {
        font-size: $font_lg;
        line-height: $font_lg;
      }

      @media screen and (min-width: $vp_hd) {
        font-size: $font_xl;
        line-height: $font_xl;
      }
    }
  }

  &.page {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    // filter: grayscale(70%);
    min-height: 300px;
    border-radius: 0;
    padding: 100px 0;
    text-align: center;

    h1 {
      color: $color_primary;
      font-size: $font_md - .6;
      line-height: $font_md - .6;
      font-weight: $font_black;
      margin-bottom: 0;
      margin-top: 0;
      text-shadow: 0 6px 30px $rgb_black_90;

      @media screen and (min-width: $vp_sm) {
        font-size: $font_lg;
        line-height: $font_lg;
      }

      @media screen and (min-width: $vp_hd) {
        font-size: $font_xl;
        line-height: $font_xl;
      }
    }

    h2 {
      font-size: $font_base;
      line-height: $font_base;
      font-weight: $font_normal;
      color: $white;
      margin-top: 0;
      text-shadow: 0 5px 15px $rgb_black_90;
    }
  }

  &--float-bottom {
    display: none;
    position: absolute;
    bottom: 5%;
    left: 0;
    right: 0;

    @media screen and (min-width: $vp_sm_max) {
      display: block;
    }
  }
}