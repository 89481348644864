.video {
  cursor: pointer;

  &__img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 200px;
    transition: all .3s ease;
    position: relative;

    @media screen and (min-width: $vp_sm) {
      height: 125px;
    }

    @media screen and (min-width: $vp_md) {
      height: 105px;
    }

    @media screen and (min-width: $vp_lg) {
      height: 145px;
    }

    .fa {
      font-size: $font_lg;
      color: $color_primary;
      left: 0;
      right: 0;
      line-height: 200px;
      margin: auto;
      opacity: 0;
      position: absolute;
      text-align: center;
      text-shadow: $text_shadow_lv3;
      transition: all .3s ease;

      @media screen and (min-width: $vp_sm) {
        line-height: 125px;
      }

      @media screen and (min-width: $vp_md) {
        line-height: 105px;
      }

      @media screen and (min-width: $vp_lg) {
        line-height: 145px;
      }
    }

    &:hover {
      box-shadow: $box_shadow_lv3;

      .fa {
        opacity: 1;
      }
    }
  }

  &__title {
    height: 60px;
    overflow-y: hidden;

    h4 {
      font-size: $font_sm - .2;
      font-weight: $font_light;
    }
  }
}