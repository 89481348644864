.trailer {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1020;
  -webkit-overflow-scrolling: touch;
  outline: 0;

  &.fade &__dialog {
    @include translate(0, -25%);
    @include transition-transform(.3s ease-out);
  }

  &.in &__dialog { @include translate(0, 0) }

  &__dialog {
    position: relative;
    width: 100vw;
    margin-top: $navbar_height_xs + px;

    @media screen and (min-width: $vp_sm) {
      margin-top: $navbar_height_sm + px;
    }

    @media screen and (min-width: $vp_md) {
      margin-top: $navbar_height_md + px;
    }

    @media screen and (min-width: $vp_lg) {
      margin-top: $navbar_height_lg + px;
    }

    @media screen and (min-width: $vp_hd) {
      margin-top: $navbar_height_hd + px;
    }

    @media screen and (min-width: $vp_4k) {
      margin-top: $navbar_height_4k + px;
    }
  }

  // Actual trailer
  &__content {
    position: relative;
    background-color: $white;
    background-clip: padding-box;
    outline: 0;
    height: calc(100vh - #{$navbar_height_xs + px});
    overflow-y: hidden;
    padding: 0;

    @media screen and (min-width: $vp_sm) {
      height: calc(100vh - #{$navbar_height_sm + px});
    }

    @media screen and (min-width: $vp_md) {
      height: calc(100vh - #{$navbar_height_md + px});
    }

    @media screen and (min-width: $vp_lg) {
      height: calc(100vh - #{$navbar_height_lg + px});
    }

    @media screen and (min-width: $vp_hd) {
      height: calc(100vh - #{$navbar_height_hd + px});
    }

    @media screen and (min-width: $vp_4k) {
      height: calc(100vh - #{$navbar_height_4k + px});
    }
  }

  // Top section of the trailer w/ title and dismiss
  &__header {
    padding: 0;
    @include clearfix;
  }

  &__body {
    position: relative;
    padding: 0;
  }

  .trailer-footer {
    padding: 0;
    @include clearfix; // clear it in case folks use .pull-* classes on buttons
  }

  /**
    * Body Classes
    * @constructor
  */
  &--open {
    overflow: hidden;
  }

  &--open .trailer {
    overflow-x: hidden;
    overflow-y: auto;
  }

  /**
    * Backdrop Class
    * @constructor
  */
  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    // Fade for backdrop
    &.fade { @include opacity(0); }
    &.in { @include opacity(0); }
  }
}

// Measure scrollbar width for padding body during trailer show/hide
.trailer-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.trailer__header {
  border-bottom: 1px solid $gray_lv1;
  padding-left: 15px;

  @media screen and (min-width: $vp_md) {
    text-align: center;
  }
}

.trailer__header .nav-pills {
  display: inline-block;
  margin-bottom: -10px;
  margin-right: -30px;
}

.trailer__header h3 {
  display: inline-block;
  font-size: $font_sm;
  font-weight: $font_thin;
  line-height: $navbar_height_xs + px;
  margin-top: 0;
  margin-bottom: 0;
  margin-bottom: -10px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70%;
  white-space: nowrap;

  @media screen and (min-width: $vp_sm) {
    font-size: $font_base;
    line-height: $navbar_height_sm + px;
    width: 85%;
  }

  @media screen and (min-width: $vp_md) {
    line-height: $navbar_height_md + px;
  }

  @media screen and (min-width: $vp_lg) {
    line-height: $navbar_height_lg + px;
  }

  @media screen and (min-width: $vp_hd) {
    line-height: $navbar_height_hd + px;
    font-size: $font_md;
  }

  @media screen and (min-width: $vp_4k) {
    line-height: $navbar_height_4k + px;
  }
}

.trailer__header .nav-pills > li + li {
  margin-left: 0;
}

.trailer__header .nav-pills > li > a {
  padding-top: 0;
  padding-bottom: 0;
  height: $navbar_height_xs + px;
  line-height: $navbar_height_xs + px;
  padding: 0 20px;
  font-weight: $font_light;
  border-radius: 0;
  color: $gray_lv3;

  @media screen and (min-width: $vp_sm) {
    height: $navbar_height_sm + px;
    line-height: $navbar_height_sm + px;
  }

  @media screen and (min-width: $vp_md) {
    height: $navbar_height_md + px;
    line-height: $navbar_height_md + px;
  }

  @media screen and (min-width: $vp_lg) {
    height: $navbar_height_lg + px;
    line-height: $navbar_height_lg + px;
  }

  @media screen and (min-width: $vp_hd) {
    height: $navbar_height_hd + px;
    line-height: $navbar_height_hd + px;
  }

  @media screen and (min-width: $vp_4k) {
    height: $navbar_height_4k + px;
    line-height: $navbar_height_4k + px;
  }

  &:hover {
    background-color: lighten($gray_lv1, 5%);
    color: $gray_lv5;
  }

  i {
    vertical-align: middle;
  }
}

.trailer__header button.close {
  float: right;
  font-size: $font_md;
  font-weight: $font_light;
  color: $gray_lv3;
  text-shadow: 0 1px 0 $white;
  border-left: 1px solid $gray_lv1;
  opacity: .2;
  height: $navbar_height_xs + px;
  line-height: $navbar_height_xs + px;
  padding: 0 30px;
  outline: 0;
  opacity: 1;

  @media screen and (min-width: $vp_sm) {
    height: $navbar_height_sm + px;
    line-height: $navbar_height_sm + px;
  }

  @media screen and (min-width: $vp_md) {
    height: $navbar_height_md + px;
    line-height: $navbar_height_md + px;
  }

  @media screen and (min-width: $vp_lg) {
    height: $navbar_height_lg + px;
    line-height: $navbar_height_lg + px;
  }

  @media screen and (min-width: $vp_hd) {
    height: $navbar_height_hd + px;
    line-height: $navbar_height_hd + px;
  }

  @media screen and (min-width: $vp_4k) {
    height: $navbar_height_4k + px;
    line-height: $navbar_height_4k + px;
  }

  &:hover {
    color: $gray_lv8;
  }
}