.message {
  padding: 5px;
  width: 100%;

  p {
    margin-bottom: 0;
  }

  &--success {
    background-color: $color_success;
    color: $white;
  }

  &--failed {
    background-color: $color_failed;
    color: $white;
  }
}
