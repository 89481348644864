.color {

  &__block {
    width: 100%;
    margin-bottom: 5px;
    color: $white;
    padding: 5px 10px;
  }

  &--primary { background-color: $color_primary; }
  &--secondary { background-color: $color_secondary; }
  &--failed { background-color: $color_failed; }
  &--warning { background-color: $color_warning; }
  &--action { background-color: $color_action; }
  &--google { background-color: $color_google; }
  &--facebook { background-color: $color_facebook; }
  &--twitter { background-color: $color_twitter; }
}

.gray {

  &--lv1 {
    background-color: $gray_lv1;
    color: $black;
  }

  &--lv2 {
    background-color: $gray_lv2;
    color: $black;
  }

  &--lv3 { background-color: $gray_lv3; }
  &--lv4 { background-color: $gray_lv4; }
  &--lv5 { background-color: $gray_lv5; }
  &--lv6 { background-color: $gray_lv6; }
  &--lv7 { background-color: $gray_lv7; }
  &--lv8 { background-color: $gray_lv8; }
}

.box-shadow {

  &--lv1 { box-shadow: $box_shadow_lv1; }
  &--lv2 { box-shadow: $box_shadow_lv2; }
  &--lv3 { box-shadow: $box_shadow_lv3; }
  &--lv4 { box-shadow: $box_shadow_lv4; }
  &--lv5 { box-shadow: $box_shadow_lv5; }
  &--lv6 { box-shadow: $box_shadow_lv6; }
  &--lv7 { box-shadow: $box_shadow_lv7; }
  &--lv8 { box-shadow: $box_shadow_lv8; }
}
