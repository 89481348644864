html body .gform_wrapper .gform_footer input.gform_button,
.btn {
  font-family: $button_font_family;
  font-weight: $button_font_weight;
  font-style: $button_font_style;
  border-radius: $button_radius;
  box-shadow: $button_shadow;
  line-height: $line_height_base;
  min-height: $button_height;
  padding: $button_padding;
  position: relative; //  Useful when positioning icons
  margin-bottom: 0; // Remove the bootstrap default to allow shorthand
  margin: $button_margin;
  transition: all .3s ease;

  i {
    vertical-align: middle;
  }

  &.active.focus,
  &:focus,
  &.focus {
    outline: none !important; // No one likes the default outline o buttons
  }

  &--link {
    box-shadow: none;
  }

  /**
    * .button--input
    * Sometimes the buttons to be
    * the same height as an input
  */
  &--input {
    min-height: $input_height;
    min-width: $input_height * 1.50;
    margin-bottom: 0;
  }

  &--cta {
    @include button-variant($color_primary, transparent, $color_primary);
    border-radius: 0;
    border-width: 2px;
    font-size: $font_base;
    font-weight: $font_light;
    font-style: normal;
    padding: 15px 15px;
    min-width: 200px;

    @media screen and (min-width: $vp_sm) {
      font-size: $font_md;
    }

    &:hover,
    &:active,
    &.active,
    &.active:hover,
    &.active:focus,
    &:active:hover,
    &:active:focus,
    &.active.focus {
      color: $white;
      background-color: $color_primary;
      border-color: $color_primary;
    }

    &:focus,
    &.focus {
      color: $color_primary;
      background-color: transparent;
      border-color: $color_primary;
    }
  }

  &--jumbotron {
    @include button-variant($white, transparent, $white);
    border-radius: 0;
    border-width: 2px;
    font-style: normal;
    padding: 15px 15px;
    min-width: 200px;

    &:hover,
    &:active,
    &.active,
    &.active:hover,
    &.active:focus,
    &:active:hover,
    &:active:focus,
    &.active.focus {
      color: $black;
      background-color: $white;
      border-color: $white;
    }

    &:focus,
    &.focus {
      color: $white;
      background-color: transparent;
      border-color: $white;
    }
  }


  &--primary {
    @include button-variant($color_primary, $white, $color_primary);

    &:hover,
    &:active,
    &.active,
    &.active:hover,
    &.active:focus,
    &:active:hover,
    &:active:focus,
    &.active.focus {
      color: $white;
      background-color: $color_primary;
      border-color: $color_primary;
    }

    &:focus,
    &.focus {
      color: $color_primary;
      background-color: $white;
      border-color: $color_primary;
    }
  }

  &--secondary {
    @include button-variant($color_primary, $color_secondary, $color_secondary);

    &:hover,
    &:active,
    &.active,
    &.active:hover,
    &.active:focus,
    &:active:hover,
    &:active:focus,
    &.active.focus {
      color: $color_secondary;
      background-color: $color_primary;
      border-color: $color_primary;
    }

    &:focus,
    &.focus {
      color: $color_primary;
      background-color: $color_secondary;
      border-color: $color_secondary;
    }
  }

  &--create,
  &--action {
    @include button-variant($white, $color_action, $color_action);

    &:hover,
    &:active,
    &.active,
    &.active:hover,
    &.active:focus,
    &:active:hover,
    &:active:focus,
    &.active.focus {
      color: $color_action;
      background-color: $white;
      border-color: $color_action;
    }

    &:focus,
    &.focus {
      color: $white;
      background-color: $color_action;
      border-color: $color_action;
    }
  }

  &--toggle {
    @include button-variant($color_primary, $white, $color_primary);
    transition: all .1s ease;
    box-shadow: none;

    &:hover,
    &:active,
    &.active,
    &.active:hover,
    &.active:focus,
    &:active:hover,
    &:active:focus,
    &.active.focus {
      color: $white;
      background-color: $color_primary;
      border-color: $color_primary;
    }

    &:focus,
    &.focus {
      color: $white;
      background-color: $color_primary;
      border-color: $color_primary;
    }
  }

  &--cancel,
  &--delete {
    @include button-variant($white, $color_failed, $color_failed);

    &:hover,
    &:active,
    &.active,
    &.active:hover,
    &.active:focus,
    &:active:hover,
    &:active:focus,
    &.active.focus {
      color: $color_failed;
      background-color: $white;
      border-color: $color_failed;
    }

    &:focus,
    &.focus {
      color: $white;
      background-color: $color_failed;
      border-color: $color_failed;
    }
  }

  &--round {
    border-radius: 100%;
    height: 45px;
    width: 45px;
  }
}

.gform_wrapper .top_label .gfield_label {
  margin-bottom: 0;
}
.gform_wrapper textarea.medium {
    height: 100px;
}


html body .gform_wrapper .gform_footer input[type="submit"],
html body .gform_wrapper .gform_footer input.gform_button {
  @include button-variant($color_primary, $white, $color_primary);
  box-shadow: none !important;
  font-size: $font_md;
  font-weight: $font_light;
  font-style: normal;
  border: 1px solid $color_primary;

  &:hover,
  &:active,
  &.active,
  &.active:hover,
  &.active:focus,
  &:active:hover,
  &:active:focus,
  &.active.focus {
    color: $white;
    background-color: $color_primary;
    border-color: $color_primary;
  }

  &:focus,
  &.focus {
    color: $color_primary;
    background-color: $white;
    border-color: $color_primary;
  }
}