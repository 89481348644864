select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="button"],
input[type="color"] {
  @extend .form-control;

  border-radius: $input_radius;
  border: $input_border;
  box-shadow: $input_box_shadow;
  height: $input_height;
  font-style: $input_font_style;
  font-weight: $font_light;
  border-top: 0;
  border-left: 0;
  border-right: 0;

  &:focus {
    border-color: $color_secondary;
    box-shadow: none;
    outline: none;
  }

  &::-webkit-input-placeholder {
    color: $input_font_color;
    text-align: left;
  }
}

label {
  font-weight: $font_light;
  font-style: $label_font_style;
  font-size: $label_font_size;
}


[placeholder]:focus::-webkit-input-placeholder,
.form-control:focus::-webkit-input-placeholder,
input[type="text"]:focus::-webkit-input-placeholder {
  transition: all .2s ease;
  text-align: right;
  font-size: $font_sm;
}


.input {

  &__group {
    @extend .input-group;
    position: relative;
    border: $input_border;
    border-radius: $input_radius;
    overflow: hidden;

    &::after {
      z-index: 9;
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 100%;
      top: 0;
      box-shadow: $input_box_shadow;
      background-color: transparent;
      pointer-events: none;
    }

    .input-group-addon {
      background-color: transparent;
      border: 0;
      box-shadow: none;
      color: $input_font_color;
      min-width: $input_height;
    }

    input[type='text'] {
      box-shadow: none;
      border: 0;
      z-index: -1;
    }
  }
}
