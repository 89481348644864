@mixin text-size($text-size, $breakpoint-name: 'default') {
  $text-size-properties: text-properties-for($text-size, $breakpoint-name);

  @if $text-size-properties {
    font-size: map-get($text-size-properties, 'font-size');
    font-weight: map-get($text-size-properties, 'font-weight');
    letter-spacing: map-get($text-size-properties, 'letter-spacing');
    line-height: map-get($text-size-properties, 'line-height');
    margin-top: map-get($text-size-properties, 'margin-top');
    margin-bottom: map-get($text-size-properties, 'margin-bottom');
    text-transform: map-get($text-size-properties, 'text-transform');
  }
}

@mixin responsive_text_size($text-size, $default-breakpoint: 'default') {
  @include text-size($text-size, $default-breakpoint);

  $text-breakpoints-map: text-breakpoints-for($text-size);
  $text-breakpoints-keys: map-keys($text-breakpoints-map);

  @each $breakpoint-name in $text-breakpoints-keys {
    @if $breakpoint-name != $default-breakpoint {
      @include respond-above($breakpoint-name) {
        @include text-size($text-size, $breakpoint-name);
      }
    }
  }
}
