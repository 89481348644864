.modal {
  top: $navbar_height_xs + px;

  @media screen and (min-width: $vp_sm) {
    top: $navbar_height_sm + px;
  }

  &--nav {
    padding-right: 0 !important;
    padding-left: 0 !important;
    z-index: 99;

    .close {
      font-weight: $font_light;
      opacity: 1 !important;
      background-color: $black;
      color: $color_secondary;
      text-shadow: none;
      margin: 0;
      border-radius: 0;
      padding: 15px;
      border: 0;
      transition: all .5s ease;
      min-height: 55px;

      &:hover {
        color: $black;
        background-color: $color_secondary;
      }
    }

    .modal-dialog {
      width: 100%;
      margin: 0;
    }

    .modal-content {
      box-shadow: none;
      border: 0;
      border-radius: 0;

      @media screen and (min-width: $vp_sm) {
        min-height: calc(100vh - #{$navbar_height_sm + px});
      }
    }

    .modal-header {
      border-bottom: 0;
    }
  }
}
