
// ::-webkit-scrollbar              { /* 1 */ }
// ::-webkit-scrollbar-button       { /* 2 */ }
// ::-webkit-scrollbar-track        { /* 3 */ }
// ::-webkit-scrollbar-track-piece  { /* 4 */ }
// ::-webkit-scrollbar-thumb        { /* 5 */ }
// ::-webkit-scrollbar-corner       { /* 6 */ }
// ::-webkit-resizer                { /* 7 */ }

/* Let's get this party started */
::-webkit-scrollbar {
  width: 5px;

  &:horizontal {
    height: 5px;
  }
}



/* Track */
::-webkit-scrollbar-track {
  background: $rgb_white_10;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color_primary;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: $color_primary;
}
