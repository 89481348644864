.height {

  @media screen and (min-width: $vp_sm) {
    &-min--10 {
      min-height: 100px;
    }

    &-min--20 {
      min-height: 200px;
    }

    &-min--30 {
      min-height: 300px;
    }

    &-min--40 {
      min-height: 400px;
    }

    &-min--50 {
      min-height: 500px;
    }
  }
}
