// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import '../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss';
@import '../../bower_components/font-awesome/scss/font-awesome.scss';
// endbower
@import '_font_montserrat';
@import 'lib/functions/_responsive';
@import 'lib/functions/_typography';
@import 'lib/mixins/_responsive';
@import 'lib/mixins/_typography';
@import '_variables';
@import '_base';

// Layouts
@import 'layouts/_navbar';
@import 'layouts/_footer';
@import 'layouts/_styleguide';
@import 'layouts/_container';

// Modules
@import 'modules/_jumbotron';
@import 'modules/_typography';
@import 'modules/_scrollbars';
@import 'modules/_buttons';
@import 'modules/_dropdown-menus';
@import 'modules/_inputs';
@import 'modules/_modals';
@import 'modules/_popovers';
@import 'modules/_messages';
@import 'modules/_trailer';
@import 'modules/_titles';
@import 'modules/_media';
@import 'modules/_labels';
@import 'modules/_videos';
@import 'modules/_sidebar';
@import 'modules/_carousels';

// Helpers
@import 'helpers/_grids';
@import 'helpers/_bg-colors';
@import 'helpers/_display';
@import 'helpers/_height';
@import 'helpers/_vertical-rhythm';

.list-image {
  li > img {
    max-height: 20px;
    margin-top: 20px;

    @media screen and (min-width: $vp_sm) {
      max-height: 50px;
      margin-top: 20px;
    }
  }

  &--sm {
    li > img {
      max-height: 30px;
      margin-top: 10px;
    }
  }
}

.page-header {
  padding: 30px 0;
  margin: 0;
  border: 0;

    h1 {
      font-size: $font_md - .6;
      line-height: $font_md - .6;
      font-weight: $font_black;
      color: $color_primary;
      margin-bottom: 0;
      margin-top: 0;
      text-shadow: 0 6px 30px $rgb_black_90;

      @media screen and (min-width: $vp_sm) {
        font-size: $font_lg;
        line-height: $font_lg;
      }

      @media screen and (min-width: $vp_hd) {
        font-size: $font_xl;
        line-height: $font_xl;
      }
    }

    h2 {
      font-size: $font_base;
      line-height: $font_base;
      font-weight: $font_normal;
      color: $white;
      margin-top: 0;
      text-shadow: 0 5px 15px $rgb_black_90;
    }
}