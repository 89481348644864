.has-success {
  border-color: $color_secondary;
  box-shadow: 0 0 75px $rgb_white_80;
}

.has-success .form-control,
.has-success .form-control:focus,
.has-success input[type="text"]:focus {
  border-color: $color_secondary;
  box-shadow: none;
}

.form-group {
  position: relative;
}

.popover {
  display: block;
  font-weight: $font_semibold;
  max-width: 100%;

  &.top {
    top: -52px;
    left: 0;
    margin-top: 0;

    > .arrow {
      left: 20%;
    }
  }

  &.inputmessage {

    &--error {
      background-color: $color_primary;
      color: $color_secondary;

      &.top > .arrow {
        border-top-color: $color_primary;
      }

      &.top > .arrow::after {
        border-top-color: $color_primary;
      }
    }

    // &--success {
    //  background-color: $brand_green;
    //  color: $white;

    //  &.top > .arrow {
    //    border-top-color: $brand_green;
    //  }

    //  &.top > .arrow:after {
    //    border-top-color: $brand_green;
    //  }
    // }
  }
}
