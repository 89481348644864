$grid_lines: #f2f6f2;
$row_color: rgba(177, 224, 236, .4);
$col_padding: rgba(82, 186, 213, .4);

/*
 * Use for testing vertical rythym
*/
.grid {

  &__lines {
    &:after {
      position: absolute;
      width: auto;
      height: auto;
      z-index: 9999;
      content: '';
      display: block;
      pointer-events: none;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url(https://basehold.it/image.php?height=26) repeat top left;
    }

    &:active:after {
      display: none;
    }
  }

  &__system {

    .container > .row {
      background-color: $row_color;

      > [class*='col-'] {
        position: relative;
        z-index: 10;

        &::after {
          background-color: $col_padding;
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 15px;
          bottom: 0;
          left: 15px;
          z-index: -1;
        }
      }
    }
  }
}
